<template>
  <div>
    <div class="table-responsive">
      <table class="table table-hover text-nowrap">
        <thead>
          <tr>
            <th>Date</th>
            <th>SBC IP</th>
            <th>ANI</th>
            <th>ANI Type</th>
            <th>Requests</th>
            <th>Success</th>
            <th
              colspan="3"
              class="text-center"
            >
              Attestation
            </th>
            <th
              colspan="2"
              class="text-center"
            >
              Failed
            </th>
          </tr>
          <tr>
            <th />
            <th />
            <th />
            <th />
            <th />
            <th />
            <th>A</th>
            <th>B</th>
            <th>C</th>
            <th>CPS Exceeded</th>
            <th>Whitelist</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
          </tr>
          <tr>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
            <td>item</td>
          </tr>
        </tbody>
      </table>
      <a-pagination
        v-if="total"
        class="pagination-right"
        :default-current="1"
        :total="total"
        @change="pagination($event)"
      />
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import { Modal } from 'ant-design-vue'

export default {
  data() {
    return {
      step: 10,
      skip: 0,
      total: null,
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    pagination(int) {
      this.skip = 0
      this.skip += this.step * int - this.step
      this.loadItems()
    },
    loadItems() {
      //   axios
      //     .get(`user?take=${this.step}&skip=${this.skip}`)
      //     .then((res) => {
      //       this.items = res.data.data
      //       this.total = res.data.count
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
    },
  },
}
</script>

<style scoped>
 .pagination-right {
   text-align: right !important;
   margin-right: 86px !important;
 }
</style>
